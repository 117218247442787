import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isError: false,
    errorCode: null,
    errorMsg: null,
    callback: null,
}

export const errorSlice = createSlice({
    name: 'isError',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.isError = true
            state.errorMsg = action.payload.errorMsg
            state.errorCode = action.payload.errorCode
            state.callback = action.payload.callback
        },
        clearError: (state) => {
            state.isError = false
            state.errorMsg = null
            state.errorCode = null
        },
    },
})

// Action creators are generated for each case reducer function
export const { setError, clearError } = errorSlice.actions
export default errorSlice.reducer