import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: true,
}

export const loadingSlice = createSlice({
    name: 'isLoading',
    initialState,
    reducers: {
        startLoad: (state) => {
            state.value = true
        },
        endLoad: (state) => {
            state.value = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoad, endLoad } = loadingSlice.actions
export default loadingSlice.reducer