import React, { Suspense } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './style/custom.scss';
import './style/variable.scss';
import './index.css';

const Main = React.lazy(() => import('routes/Main'));
const Admin = React.lazy(() => import('screens/Admin/Admin'));
const Login = React.lazy(() => import('screens/Login'));
const LuckyDraw = React.lazy(() => import('routes/LuckyDraw'));
const LuckyDrawMain = React.lazy(() => import('screens/LuckyDraw/Main/Main'));
const MissionList = React.lazy(() =>
  import('screens/LuckyDraw/Mission/MissionList')
);
const RewardHistory = React.lazy(() =>
  import('screens/LuckyDraw/RewardHistory/RewardHistory')
);

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>loading...</div>}>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/spin_screen' element={<Admin />} />
          <Route path='/login' element={<Login />} />
          <Route path='/lucky-draw' element={<LuckyDraw />}>
            <Route path='' index element={<LuckyDrawMain />} />
            <Route path='missions' element={<MissionList />} />
            <Route path='rewards' element={<RewardHistory />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
